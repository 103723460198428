

import { Factcheck, ScrapedArticle } from '@/types';
import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

import * as FactcheckServices from '@/services/FactCheckService';

@Component
export default class UseScrapedArticleDialog extends Vue {
    @VModel({ required: true }) show!: boolean;
    @Prop({ required: true }) article!: ScrapedArticle;

    addToFactcheck = false;

    factchecks = [] as Factcheck[]

    selectedFactcheck = null as Factcheck | null;

    async mounted() {
        const data = await FactcheckServices.GetAllFactChecks({
            query: '',
            category: null,
            dateRange: null,
            numericRating: null,
            partners: [],
        });
        this.factchecks = data;
    }

}
